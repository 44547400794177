<template>
  <div>
  <v-row v-if="interviews.length > 0" >
    <v-col>
    <v-card tile class="ma-auto mt-3" width="80%">
      <v-list two-line>
        <v-subheader>{{ interviews.length }} entretien{{ s(interviews) }}
          <v-spacer/>
          <v-btn small class="mt-4" color="light-blue" @click="dialog.add = true;" text>
            <v-icon>mdi-plus</v-icon> Ajouter un entretien
          </v-btn>
          <v-btn small class="mt-4" color="primary" :to="`/patient/${patientId}/corpus`" text>
            <v-icon>mdi-plus</v-icon> Importer un corpus
          </v-btn>
        </v-subheader>
        <v-list-item-group v-model="item" color="primary">
          <v-list-item v-for="(item, i) in interviews" :key="i" :to="`${item.link}`">
            <v-list-item-content>
              <v-list-item-title>
                {{ item.name }}
              </v-list-item-title>
              <v-list-item-subtitle>
                <v-icon small
                :color="item.textFile ? 'green' : 'red'"
                v-text="item.textFile ?
                  'mdi-comment-check-outline' : 'mdi-comment-remove-outline'">
              </v-icon> Transcription
                <v-icon small>mdi-calendar</v-icon> {{ item.date }}
                <v-icon small>mdi-comment-text</v-icon> {{ item.comment | truncate(120) }}
              </v-list-item-subtitle>
            </v-list-item-content>

            <v-list-item-action class="flex-row">
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-btn small icon
                  @click.stop.prevent="interviewIdToDelete = item._id; dialog.confirmation=true;"
                  v-on="on"><v-icon>mdi-delete</v-icon>
                </v-btn>
              </template>
              <span>Supprimer</span>
            </v-tooltip>
          </v-list-item-action>
        </v-list-item>
      </v-list-item-group>
    </v-list>
  </v-card>
</v-col>
</v-row>
<v-row class="justify-center d-flex mt-12" v-else>
  <v-col class="text-center">
    <v-img class="mx-auto mb-5" src="../../../assets/no-data.svg" max-width="200"></v-img>
    <p class="display-1 font-weight-thin grey--text text--lighten-1">
      Pas d'entretien à afficher
    </p>
    <v-btn small class="mt-4" color="teal" outlined @click="dialog.add = true;">
      <v-icon small>mdi-plus</v-icon> Ajouter un entretien
    </v-btn><br/>
    <v-btn small class="mt-4" color="primary" :to="`/patient/${patientId}/corpus`" outlined>
      <v-icon small>mdi-plus</v-icon> Importer un corpus
    </v-btn>
  </v-col>
</v-row>

<v-dialog v-model="dialog.add" max-width="35%">
  <v-card>
    <v-card-title class="headline">Ajouter un entretien</v-card-title>
    <v-col>
      <FormAlert :info="{alert}"></FormAlert>
    </v-col>
    <v-form ref="form" v-on:submit.prevent="add()" v-model="valid">
    <v-card-text>
        <v-text-field
        label="Titre"
        name="name"
        prepend-icon="mdi-account"
        type="text"
        :counter="64"
        :rules="this.rules.name"
        v-model="newInterview.name"></v-text-field>
  </v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn color="red" text @click="dialog.add = !dialog.add">Annuler</v-btn>
      <v-btn type="submit" :disabled="!valid" color="green darken-1" outlined>
        Ajouter
      </v-btn>
    </v-card-actions>
    </v-form>
  </v-card>
</v-dialog>
<v-dialog v-model="dialog.confirmation" persistent max-width="35%">
  <v-card>
    <v-card-title class="headline">Confirmation</v-card-title>
    <v-card-text>
      <p>L'entretien va être définitivement supprimé.</p>
      <p>Êtes-vous sûr de vouloir continuer ?</p>
    </v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn color="green darken-1" text @click="dialog.confirmation = false;">
        Annuler
      </v-btn>
      <v-btn color="red" outlined @click="remove(interviewIdToDelete);">
        Supprimer l'entretien
      </v-btn>
    </v-card-actions>
  </v-card>
</v-dialog>
</div>
</template>

<script>
import MixinInterviews from '@/mixins/interviews';
import MixinPatients from '@/mixins/patients';
import FormAlert from '@/components/common/forms/Alert.vue';

export default {
  name: 'PatientInterviewTab',
  mixins: [MixinPatients, MixinInterviews],
  components: {
    FormAlert,
  },
  props: {
    info: Object,
  },
  data: () => ({
    dialog: { add: false, confirmation: false },
    tabs: null,
    valid: false,
    item: 0,
    interviews: [],
    newInterview: { name: 'Sans titre' },
    interviewIdToDelete: 0,
  }),

  computed: {
    patient() {
      return this.info.patient;
    },

    patientId() {
      return this.info.patient.id;
    },
  },

  async mounted() {
    this.interviews = await this.getInterviewsList(this.patientId);

    this.interviews.forEach(async (item) => {
      const interview = item;
      // eslint-disable-next-line
      interview.id = item._id;
      interview.link = `/patient/${this.patientId}/interview/${interview.id}`;

      interview.date = this.convertDate(item.date);
    });

    this.updateNewInterviewName();
  },

  methods: {
    updateNewInterviewName() {
      this.newInterview.name = `${this.patient.name}: interview n°${this.interviews.length + 1}`;
    },

    async add() {
      if (!this.$refs.form.validate()) {
        return;
      }

      const interview = await this.createInterview(
        this.patientId,
        { name: this.newInterview.name },
      );

      if (interview) {
        this.dialog.add = false;

        // eslint-disable-next-line
        interview.id = interview._id;
        interview.link = `/patient/${this.patientId}/interview/${interview.id}`;

        interview.date = this.convertDate(interview.date);

        this.interviews.push(interview);
        this.updateNewInterviewName();
      }
    },

    async remove(interviewId) {
      await this.deleteInterview(interviewId, this.patientId);
      this.dialog.confirmation = false;
      this.interviews = this.interviews.filter((item) => interviewId !== item.id);
      this.updateNewInterviewName();
    },
  },
};
</script>
