<template>
  <v-col cols="2">
    <v-img src="../../assets/patient.svg" class="ma-auto mb-6" width="50%"></v-img>
    <p class="headline align-stretch">
      {{ info.patient.name }}
      <v-icon v-if="info.patient.gender" v-text="genderIcon[info.patient.gender-1]"></v-icon>
    </p>
  <p class="subtitle"><span class="overline">Adresse</span>
      <br/>{{ info.patient.email }}</p>
   <p v-if="!!info.patient.initials" class="subtitle"><span class="overline">Initiales
   </span><br/>{{ info.patient.initials }}</p>
    <p v-if="!!info.patient.number" class="subtitle"><span class="overline">Numéro
    </span><br/>{{ info.patient.number.toString().padStart(3, '0') }}</p>
    <p v-if="!!info.patient.birth" class="subtitle"><span class="overline">Date de naissance
    </span><br/>{{ this.convertDate(info.patient.birth) }}</p>
    <p v-if="!!info.patient.inclusion" class="subtitle"><span class="overline">Date d'inclusion
    </span><br/>{{ this.convertDate(info.patient.inclusion) }}</p>
    <p v-if="!!info.patient.city" class="subtitle"><span class="overline">Commune de naissance
    </span><br/>{{ info.patient.city }}</p>
    <p v-if="!!info.patient.path" class="subtitle"><span class="overline">Parcours
    </span><br/>{{ pathValue }}</p>
    <p v-if="!!info.patient.lifestyle" class="subtitle"><span class="overline">Mode de vie
    </span><br/>{{ lifestyleValue }}</p>
    <p v-if="!!info.patient.profession" class="subtitle"><span class="overline">Profession
    </span><br/>{{ info.patient.profession }}</p>

    <div class="text-center">
      <v-tooltip bottom>
        <template v-slot:activator="{ on }">
          <v-btn small icon v-on="on" :to="`/patient/${info.patient.id}/update`">
            <v-icon>mdi-pen</v-icon>
          </v-btn>
        </template>
        <span>Modifier</span>
      </v-tooltip>

      <v-tooltip bottom>
        <template v-slot:activator="{ on }">
          <v-btn disabled small icon v-on="on"><v-icon>mdi-share-variant</v-icon></v-btn>
        </template>
        <span>Partager</span>
      </v-tooltip>

      <v-tooltip bottom>
        <template v-slot:activator="{ on }">
          <v-btn small icon @click="dialog.confirmation = true;" v-on="on">
            <v-icon>mdi-delete</v-icon>
          </v-btn>
        </template>
        <span>Supprimer</span>
      </v-tooltip>
    </div>

    <v-dialog v-model="dialog.confirmation" persistent max-width="35%">
      <v-card>
        <v-card-title class="headline">Confirmation</v-card-title>
        <v-card-text>
          <p>Le dossier du patient va être définitivement supprimé.</p>
          <p>Êtes-vous sûr de vouloir continuer ?</p>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="green darken-1" text @click="dialog.confirmation = false;">
            Annuler
          </v-btn>
          <v-btn color="red" outlined
          @click="remove(info.patient.id);  dialog.confirmation = false;">
            Supprimer le dossier
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-col>

</template>

<script>

import MixinPatients from '@/mixins/patients';

export default {
  name: 'PatientInfo',
  mixins: [MixinPatients],
  props: {
    info: Object,
  },
  data: () => ({
    dialog: { confirmation: false },
    genderIcon: [
      'mdi-gender-female',
      'mdi-gender-male',
      'mdi-gender-transgender',
    ],
  }),

  computed: {
    pathValue() {
      if (this.info.patient.path) {
        return (this.path).find((element) => element.id
        === this.info.patient.path).value;
      }
      return '';
    },

    lifestyleValue() {
      if (this.info.patient.lifestyle) {
        return (this.lifestyle).find((element) => element.id
        === this.info.patient.lifestyle).value;
      }
      return '';
    },
  },

  methods: {
    async remove(patientId) {
      await this.deletePatient(patientId);
      this.$router.push('/profile');
    },
  },
};
</script>
