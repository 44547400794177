<template >
  <v-row>
    <v-col v-if="report && report.history == null">
      <div class="mt-12">
        <v-img class="mx-auto mb-5" src="../../../assets/no-data.svg" max-width="200"></v-img>
        <p class="text-center display-1 font-weight-thin grey--text text--lighten-1">
          Pas de donnée
        </p>
        <p class="subtitle grey--text text-center">
          Ajouter un entretien ou des tests pour avoir un rapport global.
        </p>
      </div>
    </v-col>
    <v-col v-else>
      <v-row v-if="loading || !report" class="mt-5 text-center">
        <v-col>
          <v-progress-circular indeterminate color="primary"></v-progress-circular>
          <p class="body font-weight-thin">Chargement...</p>
        </v-col>
      </v-row>
      <v-row v-else>
        <v-col>
        <p class="title">Historique</p>
        <v-row v-for="(item,i) in report.history"  :key="i">
          <v-col>
            <p>{{ item.name }}</p>
              <apexchart height="340" type="bar"
                :options="options" :series="item.data">
              </apexchart>
          </v-col>
        </v-row>
</v-col>
</v-row>
</v-col>
</v-row>
</template>

<script>

import VueApexCharts from 'vue-apexcharts';
import MixinPatients from '@/mixins/patients';
import MixinInterviews from '@/mixins/interviews';

export default {
  name: 'PatientReportTab',
  mixins: [MixinPatients, MixinInterviews],
  components: { apexchart: VueApexCharts },
  props: {
    patientId: String,
  },

  async mounted() {
    this.loading = true;
    const [start, end] = this.range;
    this.report = await this.getPatientHistory(this.patientId, start, end);
    this.interviewsList = await this.getInterviewsList(this.patientId);
    this.options.xaxis.categories = this.interviewsList.map((x) => (x.name));
    this.loading = false;
  },

  data: () => ({
    model: null,
    report: undefined,
    range: [0, 10],
    loading: false,

    options: {
      chart: { animations: { enabled: false } },
      plotOptions: { bar: { dataLabels: { position: 'top' } } },
      xaxis: { title: { text: 'Entretiens' } },
      chartOptions: {
        xaxis: { },
      },
      dataLabels: {
        enabled: false,
        offsetY: -14,
        style: { fontSize: '8px', colors: ['#304758'] },
      },
    },
  }),
};
</script>
