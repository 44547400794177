<template>
  <v-row>
    <v-col>
  <v-row v-if="neurotests.length > 0" >
    <v-col>
    <v-card tile class="ma-auto mt-3" width="80%">
      <v-list>
        <v-subheader>{{ neurotests.length }} tests neurocognitif{{ s(neurotests) }}
          <v-spacer/>
          <v-btn small class="mt-4" color="light-blue" @click="dialog.add = true;" text>
            <v-icon>mdi-plus</v-icon> Ajouter un test neurocognitif
          </v-btn>
        </v-subheader>
        <v-list-item-group v-model="item" color="primary">
          <v-list-item v-for="(item, i) in neurotests" :key="i" :to="`${item.link}`">
            <v-list-item-content>
              <v-list-item-title>
                {{ item.name }} ({{ item.type }})
              </v-list-item-title>
              <v-list-item-subtitle>
                {{ item.date }}
              </v-list-item-subtitle>
            </v-list-item-content>

            <v-list-item-action class="flex-row">
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-btn small icon
                  @click.stop.prevent="neurotestIdToDelete = item._id; dialog.confirmation=true;"
                  v-on="on"><v-icon>mdi-delete</v-icon>
                </v-btn>
              </template>
              <span>Supprimer</span>
            </v-tooltip>
          </v-list-item-action>
        </v-list-item>
      </v-list-item-group>
    </v-list>
  </v-card>
</v-col>
</v-row>
<v-row class="justify-center d-flex mt-12" v-else>
  <v-col class="text-center">
    <v-img class="mx-auto mb-5" src="../../../assets/no-data.svg" max-width="200"></v-img>
    <p class="display-1 font-weight-thin grey--text text--lighten-1">
      Pas de test à afficher
    </p>
    <v-btn small class="mt-4" color="teal" outlined @click="dialog.add = true;">
      <v-icon small>mdi-plus</v-icon> Ajouter un test neurocognitif
    </v-btn>
  </v-col>
</v-row>

<v-dialog v-model="dialog.add" max-width="35%">
  <v-card>
    <v-card-title class="headline">Ajouter un test neurocognitif</v-card-title>
    <v-col>
      <FormAlert :info="{alert}"></FormAlert>
    </v-col>
    <v-form ref="form" v-on:submit.prevent="add()" v-model="valid">
    <v-card-text>
        <v-text-field
        label="Titre"
        name="name"
        prepend-icon="mdi-account"
        type="text"
        :counter="64"
        :rules="this.rules.name"
        v-model="newNeurotest.name"></v-text-field>
        <v-select v-model="newNeurotest.type" :items="types" dense label="Type"
          item-text="long" :rules="this.rules.select" item-value="short" prepend-icon="mdi-apps">
        </v-select>
  </v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn color="red" text @click="dialog.add = !dialog.add">Annuler</v-btn>
      <v-btn type="submit" :disabled="!valid" color="green darken-1" outlined>
        Ajouter
      </v-btn>
    </v-card-actions>
    </v-form>
  </v-card>
</v-dialog>
<v-dialog v-model="dialog.confirmation" persistent max-width="35%">
  <v-card>
    <v-card-title class="headline">Confirmation</v-card-title>
    <v-card-text>
      <p>Le test va être définitivement supprimé.</p>
      <p>Êtes-vous sûr de vouloir continuer ?</p>
    </v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn color="green darken-1" text @click="dialog.confirmation = false;">
        Annuler
      </v-btn>
      <v-btn color="red" outlined @click="remove(neurotestIdToDelete);">
        Supprimer le test
      </v-btn>
    </v-card-actions>
  </v-card>
</v-dialog>
</v-col>
</v-row>
</template>

<script>
import MixinNeurotests from '@/mixins/neurotests';
import MixinPatients from '@/mixins/patients';
import FormAlert from '@/components/common/forms/Alert.vue';

export default {
  name: 'PatientNeurotestTab',
  mixins: [MixinPatients, MixinNeurotests],
  components: {
    FormAlert,
  },
  props: {
    info: Object,
  },
  data: () => ({
    dialog: { add: false, confirmation: false },
    valid: false,
    item: 0,
    rules: [
      (v) => !!v || 'Champ !obligatoire',
    ],
    types: [
      { short: 'TMT', long: 'Trail Making Test' },
      { short: 'PANSS', long: 'Positive and Negative Syndrome Scale' },
      { short: 'CVLT', long: 'California Verbal Learning Test' },
    ],
    neurotests: [],
    newNeurotest: { name: 'Sans titre', type: 'TMT' },
    neurotestIdToDelete: 0,
  }),

  computed: {
    patient() {
      return this.info.patient;
    },

    patientId() {
      return this.info.patient.id;
    },
  },

  async mounted() {
    this.neurotests = await this.getNeurotestsList(this.patientId);

    this.neurotests.forEach(async (item) => {
      const neurotest = item;
      // eslint-disable-next-line
      neurotest.id = item._id;
      neurotest.link = `/patient/${this.patientId}/neurotest/${neurotest.type}/${neurotest.id}`;

      neurotest.date = this.convertDate(item.date);
    });

    this.newNeurotest.name = `${this.patient.name}: neurotest n°${this.neurotests.length + 1}`;
  },

  methods: {
    async add() {
      if (!this.$refs.form.validate()) {
        return;
      }

      const neurotest = await this.createNeurotest(this.patientId, this.newNeurotest);

      if (neurotest) {
        this.dialog.add = false;

        // eslint-disable-next-line
        neurotest.id = neurotest._id;
        neurotest.link = `/patient/${this.patientId}/neurotest/${neurotest.type}/${neurotest.id}`;

        neurotest.date = this.convertDate(neurotest.date);

        this.neurotests.push(neurotest);
      }
    },

    async remove(neurotestId) {
      await this.deleteNeurotest(neurotestId, this.patientId);
      this.dialog.confirmation = false;
      this.neurotests = this.neurotests.filter((item) => neurotestId !== item.id);
    },
  },
};
</script>
