import MixinCommon from './common';

export default {
  mixins: [MixinCommon],
  methods: {
    // Get neurotest info from server
    async getNeurotestInfo(id, patient) {
      const response = await this.get(`${this.apiRoot}/patient/${patient}/neurotest/${id}`);
      const data = await response.json();

      if (response.status !== 200) {
        this.showAlertMessage('error', data);
        return null;
      }

      return data;
    },

    // Add a new neurotest
    async createNeurotest(id, data) {
      const response = await this.post(
        `${this.apiRoot}/patient/${id}/neurotest`,
        JSON.stringify(data),
      );

      if (!response.ok) {
        this.showAlertMessage('error', await response.text());
        return null;
      }

      const neurotest = await response.json();

      return neurotest;
    },

    // Update Neurotest information
    async updateNeurotest(id, patient, data) {
      const response = await this.put(`${this.apiRoot}/patient/${patient}/neurotest/${id}`,
        JSON.stringify(data));

      if (!response.ok) {
        this.showAlertMessage('error', await response.text());
      }
    },

    // Delete neurotest by ID
    async deleteNeurotest(id, patient) {
      const response = await this.delete(`${this.apiRoot}/patient/${patient}/neurotest/${id}`);

      if (!response.ok && response.status !== 404) {
        this.showAlertMessage('error', await response.text());
      }
    },

    async countNeurotests(id) {
      // Retrieve number of neurotests for this patient
      const neurotests = await this.get(`${this.apiRoot}/patient/${id}/neurotests`);
      const neurotestArray = await neurotests.json();

      return neurotestArray.length;
    },

    async getNeurotestsList(patientId) {
      const response = await this.get(`${this.apiRoot}/patient/${patientId}/neurotests`);
      const neurotests = await response.json();

      return neurotests;
    },
  },
};
