<template>
  <v-row class="flex-row align-self-stretch">
    <PatientInfo :info="{patient}"/>
    <v-col cols="10" class="pt-0">
      <v-tabs background-color="white" v-model="tabs" centered light grow>
        <v-tabs-slider></v-tabs-slider>
        <v-tab href="#tab-summary">
          <span><v-icon>mdi-chart-pie</v-icon> Résumé</span>
        </v-tab>

        <v-tab href="#tab-report">
          <span><v-icon>mdi-chart-bar</v-icon> Historique</span>
        </v-tab>

        <v-tab href="#tab-interviews">
          <span><v-icon>mdi-text-to-speech</v-icon> Entretiens</span>
        </v-tab>

        <v-tab href="#tab-tests">
          <span><v-icon>mdi-drawing</v-icon> Tests neurocognitifs</span>
        </v-tab>
        <v-tabs-items v-model="tabs">
          <v-tab-item value="tab-summary">
            <PatientSummaryTab :patientId="patientId"/>
          </v-tab-item>
          <v-tab-item value="tab-report">
            <PatientReportTab :patientId="patientId"/>
          </v-tab-item>
          <v-tab-item value="tab-interviews">
            <v-col>
              <PatientInterviewTab :info="{patient}"/>
            </v-col>
          </v-tab-item>
          <v-tab-item value="tab-tests">
            <PatientNeurotestTab :info="{patient}"/>
          </v-tab-item>
        </v-tabs-items>
      </v-tabs>
    </v-col>
  </v-row>
</template>

<script>
import MixinInterviews from '@/mixins/interviews';
import MixinPatients from '@/mixins/patients';
import PatientInfo from '@/components/patients/Info.vue';
import PatientSummaryTab from '@/components/patients/tabs/Summary.vue';
import PatientReportTab from '@/components/patients/tabs/Report.vue';
import PatientInterviewTab from '@/components/patients/tabs/Interviews.vue';
import PatientNeurotestTab from '@/components/patients/tabs/Neurotests.vue';

export default {
  name: 'Patient',
  mixins: [MixinPatients, MixinInterviews],
  components: {
    PatientInfo,
    PatientSummaryTab,
    PatientReportTab,
    PatientInterviewTab,
    PatientNeurotestTab,
  },
  props: {
    // Set by router
    patientId: String,
  },
  data: () => ({
    tabs: null,
    patient: {},
    analysis: {},
  }),

  async mounted() {
    this.patient = await this.getPatientInfo(this.patientId);
    this.patient.id = this.patientId;
  },
};
</script>
