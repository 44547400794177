<template >
  <v-row>
    <v-col v-if="summary && summary.similarity == null">
      <div class="mt-12">
        <v-img class="mx-auto mb-5" src="../../../assets/no-data.svg" max-width="200"></v-img>
        <p class="text-center display-1 font-weight-thin grey--text text--lighten-1">
          Pas de donnée
        </p>
        <p class="subtitle grey--text text-center">
          Ajouter un entretien ou des tests pour avoir un rapport global.
        </p>
      </div>
    </v-col>
    <v-col v-else>
      <v-row v-if="loading || !summary" class="mt-5 text-center">
        <v-col>
        <v-progress-circular indeterminate color="primary"></v-progress-circular>
        <p class="body font-weight-thin">Chargement...</p>
      </v-col>
      </v-row>
      <v-row v-else>
        <v-col>
          <v-row>
            <v-col>
              <p class="title mt-5">Tendance par entretien <v-chip small bordered color="error">
                <v-icon small>mdi-beaker</v-icon> Expérimental
              </v-chip>
              </p>
              <apexchart type="bar" height="350" :options="scoreOptions"
              :series="summary.score.series"></apexchart>
            </v-col>
            <v-col>
              <p class="title mt-5">Tendance globale <v-chip small bordered color="error">
                <v-icon small>mdi-beaker</v-icon> Expérimental
              </v-chip>
              </p>
              <apexchart type="donut" height="350" :options="donutOptions"
              :series="summary.score.pathology"></apexchart>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <p class="title mt-5">Résumé <v-chip small bordered color="error">
                <v-icon small>mdi-beaker</v-icon> Expérimental
              </v-chip>
              </p>
              <div id="chart">
                <apexchart type="line" height="350" :options="similarityCurve.chartOptions"
              :series="summary.similarity">
                </apexchart>
              </div>
              </v-col>
              <v-col>
                <p class="title mt-5">Profil <v-chip small bordered color="error">
                  <v-icon small>mdi-beaker</v-icon> Expérimental
                </v-chip>
                </p>
              <apexchart type="radar" :options="defaultRadarOptions" :series="summary.radar">
              </apexchart>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>

import VueApexCharts from 'vue-apexcharts';
import MixinPatients from '@/mixins/patients';
import MixinInterviews from '@/mixins/interviews';

export default {
  name: 'PatientSummaryTab',
  mixins: [MixinPatients, MixinInterviews],
  components: { apexchart: VueApexCharts },
  props: {
    patientId: String,
  },

  async mounted() {
    this.loading = true;
    this.summary = await this.getPatientSummary(this.patientId);
    this.interviewsList = await this.getInterviewsList(this.patientId);
    this.similarityCurve.chartOptions.xaxis.categories = this.interviewsList.map((x) => (x.name));
    this.scoreOptions.xaxis.categories = this.interviewsList.map((x) => (x.name));
    this.loading = false;
  },

  data: () => ({
    summary: undefined,
    loading: false,

    similarityCurve: {
      chartOptions: {
        chart: {
          height: 350,
          type: 'line',
          toolbar: { show: false },
        },
        colors: ['#77B6EA', '#545454'],
        dataLabels: { enabled: true },
        stroke: { curve: 'smooth' },
        title: { text: 'Ressemblance à TCOF', align: 'left' },
        grid: {
          borderColor: '#e7e7e7',
          row: {
            colors: ['#f3f3f3', 'transparent'],
            opacity: 0.5,
          },
        },
        markers: { size: 1 },
        xaxis: { title: { text: 'Entretiens' } },
        yaxis: {
          title: { text: '0-1' },
          labels: { show: false },
          tickAmount: 2,
          axisTicks: { show: true },
          min: 0,
          max: 1.1,
        },
        legend: {
          position: 'top',
          horizontalAlign: 'right',
          floating: true,
          offsetY: -25,
          offsetX: -5,
        },
      },
    },

    donutOptions: {
      chart: {
        type: 'donut',
      },
      labels: ['Normal', 'Pathologique'],
    },

    options: {
      chart: {
        animations: { enabled: false },
      },
      plotOptions: {
        bar: { dataLabels: { position: 'top' } },
      },
      dataLabels: {
        enabled: false,
        offsetY: -14,
        style: { fontSize: '8px', colors: ['#304758'] },
      },
    },
    scoreOptions: {
      chart: {
        type: 'bar',
        height: 350,
        stacked: true,
        stackType: '100%',
      },
      yaxis: {
        title: { text: 'Confiance (modèle stabilité des thèmes)' },
        labels: { show: false },
        tickAmount: 2,
      },
      xaxis: {
        categories: [],
      },
    },
  }),
};
</script>
